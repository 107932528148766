<template>
  <div>
    <div class="news-item" v-for="i in 9" :key="i" @click="toDetail()">
      <div class="img"></div>
      <div class="text">
        <div class="title">互联网新风向：互联网设计成中国经济增长新动能</div>
        <div class="cent">
          随着中国制造业人口红利逐渐消失，在全球市场需求不足、综合运营成本攀升的外部环境下，数字化转型成为实体经济稳定发展的关
          键。我国大力推动工业互联网建设，目前具有一定影响力的工业互联网平台超过80个，各种类型的工业APP数量超过35万个，有力支
          撑了产业提质降本增效……
          随着中国制造业人口红利逐渐消失，在全球市场需求不足、综合运营成本攀升的外部环境下，数字化转型成为实体经济稳定发展的关
          键。我国大力推动工业互联网建设，目前具有一定影响力的工业互联网平台超过80个，各种类型的工业APP数量超过35万个，有力支
          撑了产业提质降本增效……
        </div>
        <div class="date">2021.03.26</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    toDetail(){
      this.$router.push({
        path:"news/detail"
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.news-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 52px;
  &:last-child {
    margin-bottom: 0;
  }
  .img {
    width: 280px;
    height: 210px;
    background-color: #2397f3;
    border-radius: 3px;
  }
  .text {
    width: 930px;
    font-size: 16px;
    line-height: 30px;
    color: #818181;
    .title {
      font-size: 22px;
      color: #313131;
      margin: 16px 0;
    }
    .cent {
      height: 90px;
      width: 930px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .date {
      font-size: 18px;
      color: #2397f3;
      margin-top: 16px;
    }
  }
}
</style>